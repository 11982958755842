<template>
  <mPages>
    <template v-slot:mContent>
      <!--      banner-->
      <div class="banner">
        <div class="banner-img">
          <img src="../../assets/images/mobileImgs/mAbout-banner.png" alt="" />
        </div>
      </div>

      <!--      公司简介/团队介绍-->
      <div class="company">
        <div class="companyInfo w588">
          <!--          公司介绍-->
          <div class="introduce same">
            <h3>公司简介</h3>
            <p>
              杭州凡佶互连科技有限公司成立于2019年，坐落于美丽的西子湖畔。公司源自浙大，由浙大智能软件研究室应晶教授团队提供技术支撑的高级科技型公司，致力于大数据、人工智能等应用领域的产学研成果转化。公司已与多个国家级的科研院所建立了良好的合作关系，目前已有多个科研项目在有序进行，并在科研成果转换方面取得了积极的进展。根据业务发展，公司已成立凡佶互连科技北京分公司，积极组织团队参与国家信创产业的相关领域，推动中国数字经济与智慧社会的进步与发展。
            </p>
          </div>
          <!--          团队介绍-->
          <div class="team same">
            <h3>团队介绍</h3>
            <p>
              浙江大学应晶教授领衔并亲自担任首席科学家；设立跨学科专家委员会，由浙大相关实验室提供学科支持。团队依托浙江大学计算机、信息、电子、光电、控制等学科的有力支撑。
              公司大力开展高质量人才的引进工作，高度重视员工的创新能力。目前公司拥有研发及技术支持人员近40人，硕士及以上人员占职工总数30%，其中包含高级架构师、研究员、工程师、软件设计师等研发人员。技术团队结构合理，具备领先的技术方向、丰富的技术开发和较强的科技成果产业化能力。同时，严格的质量保障体系使凡佶互连的技术团队成为公司高品质产品和服务的有力保障。
            </p>
          </div>
        </div>
      </div>

      <!--      企业文化-->
      <div class="culture">
        <div class="cultureInfo w588">
          <div class="title">企业文化</div>
          <div class="content">
            <div class="shiming consame">
              <div class="icon">
                <img src="../../assets/images/icon1.png" alt="" />
              </div>
              <h3 class="subtitle">使命</h3>
              <p class="subtext">科技驱动创新 数智助力未来</p>
            </div>
            <div class="yuanjing consame">
              <div class="icon">
                <img src="../../assets/images/icon2.png" alt="" />
              </div>
              <h3 class="subtitle">愿景</h3>
              <p class="subtext">
                建设成为一家服务于<br />国家战略的创新型科技领军企业
              </p>
            </div>
            <div class="jiazhi consame">
              <div class="icon">
                <img src="../../assets/images/icon3.png" alt="" />
              </div>
              <h3 class="subtitle">价值观</h3>
              <p class="subtext">责任 发展 奉献 共赢</p>
            </div>
          </div>
        </div>
      </div>

      <!--      证书和奖项-->
      <div class="honor">
        <div class="contentTop w588">
          <div class="title">证书和奖项</div>
          <div class="content">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in certificate"
                  :key="index"
                >
                  <div class="swiper-top">
                    <img :src="item.imgPath" @click="changeImg(item.value)" />
                    <!--                  <el-image style="width: 100px; height: 100px" :src="item.imgPath" :fit="fit" />-->
                    <p>{{ item.imgName }}</p>
                  </div>
                </div>
              </div>
              <!--              <div class="swiper-pagination"></div>-->
            </div>
            <!--            <div class="tixi1 samet">-->
            <!--              <img-->
            <!--                @click="changeImg(0)"-->
            <!--                src="../../assets/images/certificate/h1.png"-->
            <!--                alt=""-->
            <!--              />-->
            <!--              <p>环境管理体系</p>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <!--      预览-->
      <div class="preview" v-show="imgShow">
        <div class="w588 imgBox">
          <button class="cancel" @click="this.imgShow = false">关 闭</button>
          <img
            v-if="imgSrcShow == 0"
            src="../../assets/images/certificate/h1.png"
            alt=""
          />
          <img
            v-if="imgSrcShow == 1"
            src="../../assets/images/certificate/h2.png"
            alt=""
          />
          <img
            v-if="imgSrcShow == 2"
            src="../../assets/images/certificate/h3.png"
            alt=""
          />
          <img
            v-if="imgSrcShow == 3"
            src="../../assets/images/certificate/chuying.png"
            alt=""
          />
          <img
            v-if="imgSrcShow == 4"
            src="../../assets/images/certificate/shifan.png"
            alt=""
          />
        </div>
      </div>
    </template>
  </mPages>
</template>

<script>
import mPages from "@/components/mobile/mPages";
import Swiper from "../../../node_modules/swiper/js/swiper.js";
import "../../../node_modules/swiper/css/swiper.min.css";
import { ref } from "vue";

export default {
  name: "mAbout",
  components: {
    mPages,
  },
  setup() {
    const imgShow = ref(false);
    const imgSrcShow = ref("");
    const certificate = [
      {
        imgPath: require("@/assets/images/certificate/h1.png"),
        imgName: "环境管理体系",
        value: "0",
      },
      {
        imgPath: require("@/assets/images/certificate/h2.png"),
        imgName: "质量管理体系",
        value: "1",
      },
      {
        imgPath: require("@/assets/images/certificate/h3.png"),
        imgName: "职业健康安全管理体系",
        value: "2",
      },
      {
        imgPath: require("@/assets/images/certificate/chuying.png"),
        imgName: '"雏鹰计划"企业',
        value: "3",
      },
      {
        imgPath: require("@/assets/images/certificate/shifan.png"),
        imgName: "创新创优示范单位",
        value: "4",
      },
    ];
    return {
      imgShow,
      imgSrcShow,
      certificate,
    };
  },
  created() {},
  mounted() {
    new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        //type: 'fraction',
        //type : 'progressbar',
        //type : 'custom',
      },
      slidesPerView: 3,
      centeredSlides: true,
      loop: true,
      initialSlide: 2,
      // pagination: '.swiper-pagination',
      paginationClickable: true,
      effect: "coverflow",
      // direction: "vertical",
      autoplay: true,
    });
  },
  methods: {
    changeImg(val) {
      this.imgShow = true;
      this.imgSrcShow = val;
    },
  },
};
</script>

<style scoped lang="scss">
//banner
.banner {
}

//公司介绍|团队介绍
.company {
  .companyInfo {
    padding-bottom: 65px;
    box-sizing: border-box;

    .introduce {
    }

    .team {
    }

    .same {
      h3 {
        font-size: 32px;
        font-weight: 600;
        color: #34383d;
        margin: 63px 0 33px;
        text-align: center;
      }

      p {
        font-size: 24px;
        color: #35383e;
        line-height: 48px;
      }
    }
  }
}

//企业文化
.culture {
  background-color: #fafafa;

  .cultureInfo {
    padding: 64px 0 65px;
    box-sizing: border-box;

    .title {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 46px;
    }

    .content {
      display: flex;
      justify-content: space-between;

      .consame {
        width: 189px;
        height: 248px;
        background-color: #4599f8;
        padding: 32px 23px 0;
        box-sizing: border-box;

        .icon {
          width: 63px;
          margin: 0 40px 21px;

          img {
            width: 100%;
          }
        }

        .subtitle {
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 12px;
        }

        .subtext {
          text-align: center;
          font-size: 16px;
          color: #fff;
          line-height: 20px;
        }
      }
    }
  }
}

//证书和奖项
.honor {
  background-color: #f1f1f3;

  .tixi4 {
    width: 300px;
    padding-bottom: 20px;
    p {
      color: #666;
      text-align: center;
      margin: 20px 0;
    }
  }
  .contentTop {
    padding-top: 64px;
    box-sizing: border-box;

    .title {
      font-size: 32px;
      font-weight: 600;
      color: #34383d;
      text-align: center;
      margin-bottom: 40px;
    }

    .content {
      padding-bottom: 25px;
      //display: flex;
      //justify-content: space-between;
      position: relative;
      height: 280px;

      img {
        //width: 166px;
        //display: block;
        //margin: 0 auto 25px;
      }

      p {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        color: #636363;
        margin-top: 5px;
      }

      .samet {
        position: absolute;
        //float: right;
      }

      .tixi2 {
        top: 30px;
        left: 200px;
      }

      .tixi3 {
        right: 0;
      }
    }
  }

  .contentBot {
    background-color: #ececee;
    padding: 0 70px 64px;
    height: 100px;
    box-sizing: border-box;
  }
}

.preview {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(200, 200, 200, 0.2);
  z-index: 1000;

  .imgBox {
    margin-top: 150px;

    .cancel {
      float: right;
      background-color: #166df7;
      border: none;
      width: 150px;
      height: 55px;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
    }
  }
}
</style>
