<template>
  <Pages>
    <template v-slot:content>
      <div class="banner">
        <img src="../assets/images/about.png" />
      </div>
      <div class="w1200">
        <div class="breadcrumb">
          <router-link to="/">首页 </router-link>
          <img
            src="../assets/images/right.png"
            class="icon-right"
          />关于凡佶<img
            src="../assets/images/right.png"
            class="icon-right"
          /><span>正文</span>
        </div>
        <div class="desc">
          <h3>公司简介</h3>
          <p>
            杭州凡佶互连科技有限公司成立于2019年，坐落于美丽的西子湖畔。公司源自浙大，由浙大智能软件研究室应晶教授团队提供技术支撑的高级科技型公司，致力于大数据、人工智能等应用领域的产学研成果转化。公司已与多个国家级的科研院所建立了良好的合作关系，目前已有多个科研项目在有序进行，并在科研成果转换方面取得了积极的进展。根据业务发展，公司已成立凡佶互连科技北京分公司，积极组织团队参与国家信创产业的相关领域，推动中国数字经济与智慧社会的进步与发展。
          </p>
        </div>
        <div class="desc team">
          <h3>团队介绍</h3>
          <p>
            浙江大学应晶教授领衔并亲自担任首席科学家；设立跨学科专家委员会，由浙大相关实验室提供学科支持。团队依托浙江大学计算机、信息、电子、光电、控制等学科的有力支撑。公司大力开展高质量人才的引进工作，高度重视员工的创新能力。目前公司拥有研发及技术支持人员近40人，硕士及以上人员占职工总数30%，其中包含高级架构师、研究员、工程师、软件设计师等研发人员。技术团队结构合理，具备领先的技术方向、丰富的技术开发和较强的科技成果产业化能力。同时，严格的质量保障体系使凡佶互连的技术团队成为公司高品质产品和服务的有力保障。
          </p>
        </div>
      </div>
      <div class="culture">
        <div class="w1200">
          <h3>企业文化</h3>
          <ul>
            <li>
              <img src="../assets/images/icon1.png" />
              <h4>使命</h4>
              <p>科技驱动创新 数智助力未来</p>
            </li>
            <li>
              <img src="../assets/images/icon2.png" />
              <h4>愿景</h4>
              <p>建设成为一家服务于<br />国家战略的创新型科技领军企业</p>
            </li>
            <li>
              <img src="../assets/images/icon3.png" />
              <h4>价值观</h4>
              <p>责任 发展 奉献 共赢</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="honor">
        <div class="bg1">
          <h3>证书和奖项</h3>
        </div>
        <!--        <div class="bg2"></div>-->
        <div class="w1200 honor-box">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in certificate"
                :key="index"
              >
                <div class="swiper-top">
                  <img :src="item.imgPath" />
                  <!--                  <el-image style="width: 100px; height: 100px" :src="item.imgPath" :fit="fit" />-->
                  <p>{{ item.imgName }}</p>
                </div>
                <div style="background-color: #fff">
                  <!--                  title-->
                  <p
                    style="
                      color: #636363;
                      line-height: 50px;
                      padding-top: 0;
                      text-align: center;
                      margin: 0;
                    "
                  >
                    {{ item.imgName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <!--          <ul>-->
          <!--            <li>-->
          <!--              <img src="../assets/images/h1.png" />-->
          <!--              <div>环境管理体系</div>-->
          <!--            </li>-->
          <!--            <li>-->
          <!--              <img src="../assets/images/h2.png" />-->
          <!--              <div>质量管理体系</div>-->
          <!--            </li>-->
          <!--            <li>-->
          <!--              <img src="../assets/images/h3.png" />-->
          <!--              <div>职业健康安全管理体系</div>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--          <div class="chuying">-->
          <!--            <img src="../assets/images/chuyingjihua.jpg" />-->
          <!--            <div>雏鹰计划</div>-->
          <!--          </div>-->
        </div>
      </div>
    </template>
    <!--    <div class="logImg">-->
    <!--      123123-->
    <!--    </div>-->
  </Pages>
</template>
<script>
import Pages from "../components/Pages.vue";
import Swiper from "../../node_modules/swiper/js/swiper.js";
import "../../node_modules/swiper/css/swiper.min.css";

export default {
  components: { Pages },
  setup() {
    const certificate = [
      {
        imgPath: require("@/assets/images/certificate/h1.png"),
        imgName: "环境管理体系",
      },
      {
        imgPath: require("@/assets/images/certificate/h2.png"),
        imgName: "质量管理体系",
      },
      {
        imgPath: require("@/assets/images/certificate/h3.png"),
        imgName: "职业健康安全管理体系",
      },
      {
        imgPath: require("@/assets/images/certificate/chuying.png"),
        imgName: '"雏鹰计划"企业',
      },
      {
        imgPath: require("@/assets/images/certificate/shifan.png"),
        imgName: "创新创优示范单位",
      },
    ];
    return {
      certificate,
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        //type: 'fraction',
        //type : 'progressbar',
        //type : 'custom',
      },
      slidesPerView: 3,
      centeredSlides: true,
      loop: true,
      initialSlide: 2,
      // pagination: '.swiper-pagination',
      paginationClickable: true,
      effect: "coverflow",
      // direction: "vertical",
      autoplay: true,
    });
  },
};
</script>
<style lang="scss" scoped>
.banner {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  color: #333;
  margin: 24px 0 44px;

  a {
    color: #0d1624;
  }

  img {
    margin: 0 10px;
  }

  .icon-right {
    width: 10px;
    height: 12px;
  }

  span {
    color: #999;
  }
}

.desc {
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #34383d;
    margin-bottom: 30px;
    text-align: center;
  }

  p {
    color: #34383d;
    line-height: 1.8;
    text-indent: 2em;
  }

  &.team {
    margin: 50px 0;
  }
}

.culture {
  background: #fafafa;
  padding: 60px 0 80px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #34383d;
    margin-bottom: 50px;
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: space-between;

    li {
      width: 378px;
      height: 230px;
      background: #4599f8;
      border-radius: 2px;
      text-align: center;
      color: #fff;

      img {
        width: 52px;
        margin: 40px auto 0;
      }

      h4 {
        font-weight: 600;
        font-size: 18px;
        margin: 21px 0 17px;
      }

      p {
        // margin-top: 17px;
        line-height: 1.8;
      }
    }
  }
}

.honor {
  position: relative;

  .bg1 {
    background: #eff1f1;
    height: 520px;
    //box-shadow: 0px 20px 10px rgba(0, 0, 0, 1);
    padding-top: 80px;
    position: relative;
  }

  .bg2 {
    background: #e5e7e9;
    height: 550px;
    position: relative;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #34383d;
    text-align: center;
  }

  .honor-box {
    position: absolute;
    top: 150px;
    left: 375px;

    .swiper-wrapper {
      margin-bottom: 150px;

      .swiper-slide {
        width: 450px;
        height: 250px;
        background: url("../assets/images/certificate/glod.png");
        background-size: 100%;

        .swiper-top {
          display: flex;
          height: 200px;

          img {
            width: 45%;
            //column-fill: auto;
            height: 100%;
            margin-right: 15px;
          }

          p {
            font-size: 20px;
            font-weight: 700;
            line-height: 200px;
            background-image: -webkit-linear-gradient(
              top,
              #bc2f08,
              #d75d10,
              #de6912
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  ul {
    // margin-top: -750px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  li {
    width: 361px;
    text-align: center;

    img {
      width: 361px;
      // height: 461px;
      background: #fff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    }

    &:nth-of-type(2) {
      margin-top: 30px;
    }

    div {
      font-size: 16px;
      color: #666;
      margin-top: 20px;
    }
  }
}
</style>
