<template>
  <mPages>
    <template v-slot:mContent>
      <!--      banner-->
      <div class="banner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../../assets/images/mobileImgs/mhome-banner.png" />
            </div>
          </div>
          <!--          <div class="swiper-pagination"></div>-->
        </div>
      </div>

      <!--      产品-->
      <div class="product">
        <div class="w588 product-info">
          <div class="text">
            <h3>C-DIS高性能通信中间件</h3>
            <p>
              以数据为中心的、完全自主可控的高性能数据交互中间件，助力应用程序搭建分布式通信架构
            </p>
            <router-link :to="{ path: '/business', query: { activeTab: 0 } }"
              >了解更多</router-link
            >
          </div>
          <div class="pic">
            <img src="../../assets/images/C-DIS.png" alt="" />
          </div>
        </div>
      </div>

      <div class="product rightText">
        <div class="w588 product-info">
          <div class="text">
            <h3>协同控制系统</h3>
            <p>模块化、可移植，采用分布式架构，支持多设备协同控制系统</p>
            <router-link :to="{ path: '/business', query: { activeTab: 1 } }"
              >了解更多</router-link
            >
          </div>
          <div class="pic">
            <img src="../../assets/images/xietong.png" alt="" />
          </div>
        </div>
      </div>

      <div class="product">
        <div class="w588 product-info">
          <div class="text">
            <h3>信创领域</h3>
            <p>
              以“3纵3横”体系为支撑，利用信息化手段提升疫情防控现场流调和风险人员排查及时性、精准性
            </p>
            <router-link :to="{ path: '/business', query: { activeTab: 2 } }"
              >了解更多</router-link
            >
          </div>
          <div class="pic">
            <img src="../../assets/images/xinchuang.png" alt="" />
          </div>
        </div>
      </div>

      <!--      合作单位-->
      <div class="company">
        <div class="content w588">
          <div class="title">合作单位</div>
          <div class="cooperation">
            <dl>
              <dd><img src="../../assets/images/partner/zjdx.png" /></dd>
              <dd><img src="../../assets/images/partner/dzdx.png" /></dd>
              <dd><img src="../../assets/images/partner/wzdx.png" /></dd>
              <dd><img src="../../assets/images/partner/sfdx.png" /></dd>
              <dd><img src="../../assets/images/partner/hgdt.png" /></dd>
              <dd><img src="../../assets/images/partner/tldq.png" /></dd>
              <dd><img src="../../assets/images/partner/rdkj.png" /></dd>
              <dd><img src="../../assets/images/partner/lxkj.png" /></dd>
              <dd><img src="../../assets/images/partner/tykj.png" /></dd>
            </dl>
          </div>
        </div>
      </div>
    </template>
  </mPages>
</template>

<script>
import mPages from "@/components/mobile/mPages";
import Swiper from "../../../node_modules/swiper/js/swiper.js";
import "../../../node_modules/swiper/css/swiper.min.css";

export default {
  name: "mHome",
  components: {
    mPages,
  },
  mounted() {
    new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        //type: 'fraction',
        //type : 'progressbar',
        //type : 'custom',
      },
      // pagination: '.swiper-pagination',
      paginationClickable: true,
      direction: "vertical",
      autoplay: true,
    });
  },
};
</script>

<style scoped lang="scss">
//banner
.banner {
  height: 640px;

  :deep(.swiper-pagination-bullet) {
    opacity: 1;
    background: #fff;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
  }

  :deep(.swiper-pagination-bullet-active) {
    background: none;
    border: 1px solid #fff;
  }
}
//核心产品
.product {
  .product-info {
    padding: 64px 0 81px;
    box-sizing: border-box;
    .text {
      h3 {
        font-size: 40px;
        font-weight: 500;
        color: #34383d;
      }
      p {
        font-size: 24px;
        line-height: 48px;
        color: #35383e;
        margin: 32px 0 46px;
      }
      a {
        border-radius: 8px;
        display: inline-block;
        width: 160px;
        height: 54px;
        background-color: #166df7;
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        line-height: 54px;
        margin-bottom: 80px;
      }
    }
    .pic {
    }
  }
}
.rightText {
  text-align: end;
  background-color: #fafafa;
}
//合作单位
.company {
  background-color: #fafafa;
  padding: 64px 0 78px;
  box-sizing: border-box;
  .content {
    .title {
      text-align: center;
      font-size: 40px;
      color: #34383d;
      font-weight: 500;
      margin-bottom: 49px;
    }
    .cooperation {
      dl {
        display: flex;
        flex-wrap: wrap;
        dd {
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
