<template>
  <Pages>
    <template v-slot:content>
      <div class="banner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../assets/images/index-banner.png" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="product box1">
        <div class="w1200 product-box">
          <div class="con">
            <h3>C-DIS高性能通信中间件</h3>
            <p>
              以数据为中心的、完全自主可控的高性能数据交互中间件，助力应用程序搭建分布式通信架构
            </p>
            <!-- <button>了解更多</button> -->
            <router-link :to="{ path: '/business', query: { activeTab: 0 } }"
              >了解更多</router-link
            >
          </div>
          <div class="img"><img src="../assets/images/C-DIS.png" /></div>
        </div>
      </div>
      <div class="product box2">
        <div class="w1200 product-box">
          <div class="con">
            <h3>协同控制系统</h3>
            <p>模块化、可移植，采用分布式架构，支持多设备协同控制系统</p>
            <div>
              <router-link :to="{ path: '/business', query: { activeTab: 1 } }"
                >了解更多</router-link
              >
            </div>
          </div>
          <div class="img"><img src="../assets/images/xietong.png" /></div>
        </div>
      </div>
      <div class="product box1">
        <div class="w1200 product-box">
          <div class="con">
            <h3>信创领域</h3>
            <p>
              以“3纵3横”体系为支撑，利用信息化手段提升疫情防控现场流调和风险人员排查及时性、精准性
            </p>
            <router-link :to="{ path: '/business', query: { activeTab: 2 } }"
              >了解更多</router-link
            >
          </div>
          <div class="img img3">
            <img src="../assets/images/xinchuang.png" />
          </div>
        </div>
      </div>
      <div class="partner">
        <div class="w1200">
          <h3>合作单位</h3>
          <dl>
            <dd><img src="../assets/images/partner/zjdx.png" /></dd>
            <dd><img src="../assets/images/partner/dzdx.png" /></dd>
            <dd><img src="../assets/images/partner/wzdx.png" /></dd>
            <dd><img src="../assets/images/partner/sfdx.png" /></dd>
            <dd><img src="../assets/images/partner/hgdt.png" /></dd>
            <dd><img src="../assets/images/partner/tldq.png" /></dd>
            <dd><img src="../assets/images/partner/rdkj.png" /></dd>
            <dd><img src="../assets/images/partner/lxkj.png" /></dd>
            <dd><img src="../assets/images/partner/tykj.png" /></dd>
          </dl>
        </div>
      </div>
    </template>
  </Pages>
</template>
<script>
import Pages from "../components/Pages.vue";
import Swiper from "../../node_modules/swiper/js/swiper.js";
import "../../node_modules/swiper/css/swiper.min.css";

export default {
  components: { Pages },

  mounted() {
    new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        //type: 'fraction',
        //type : 'progressbar',
        //type : 'custom',
      },
      // pagination: '.swiper-pagination',
      paginationClickable: true,
      direction: "vertical",
      autoplay: true,
    });
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height: 640px;
  :deep(.swiper-pagination-bullet) {
    opacity: 1;
    background: #fff;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
  }
  :deep(.swiper-pagination-bullet-active) {
    background: none;
    border: 1px solid #fff;
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product {
  .product-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 109px 0 105px;
  }
  .con {
    width: 400px;
    h3 {
      font-size: 30px;
      color: #34383d;
      font-weight: 500;
    }
    p {
      font-size: 15px;
      line-height: 2;
      margin: 10px 0 20px;
      color: #35383e;
    }
    a {
      text-align: center;
      display: block;
      width: 128px;
      height: 42px;
      line-height: 42px;
      background: #166df7;
      border-radius: 4px;
      border: 0;
      color: #fff;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        background: #4187f7;
      }
    }
  }
  .img {
    width: 406px;
    height: 320px;
  }
  .img3 {
    // width: 424px;
    // height: 308px;
  }
  &.box1 {
    background: #fff;
  }
  &.box2 {
    background: #fafafa;
    .product-box {
      flex-direction: row-reverse;
    }
    .con {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 368px;
      align-items: flex-end;
    }
  }
}
.partner {
  background: #fafafa;
  padding: 75px 0 70px;
  h3 {
    font-size: 30px;
    color: #34383d;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
  }
  dl {
    // width: 950px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    dd {
      width: 268px;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      transition: 0.2s;
      cursor: pointer;
      margin: 0 0 14px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
